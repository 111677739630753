import React from 'react';

import i18n, { contentLanguage } from '../../../utils/i18n';
import { extractDeclinableByCode } from '../../../utils';
import { productPropTypes } from '../../../utils/propTypes/product';

import { pnqCodeSorted, pnqCodeToIndent } from './constants';
import './nutritionTab.scss';

class NutritionTab extends React.Component {
  static formatPSQ(productStandardQuantities = []) {
    const formatedPNQ = {};
    let availablePNQs = [];

    productStandardQuantities.forEach((psq) => {
      psq.contains.forEach((pnq) => {
        availablePNQs.push(pnq.nutrientCode);
        if (!formatedPNQ[pnq.nutrientCode.code]) {
          formatedPNQ[pnq.nutrientCode.code] = {};
        }
        const psqName = extractDeclinableByCode(psq.name, contentLanguage);

        formatedPNQ[pnq.nutrientCode.code][psqName.data] = pnq;
      });
    });
    availablePNQs = availablePNQs.filter(
      (currentPnq, index, self) =>
        self.findIndex((pnq) => pnq.code === currentPnq.code) === index
    );
    const availablePNQsSorted = [];
    pnqCodeSorted.forEach((code) => {
      const pnq = availablePNQs.find((item) => item.code === code);
      if (pnq) {
        availablePNQsSorted.push(pnq);
      }
    });

    return { formatedPNQ, availablePNQs: availablePNQsSorted };
  }

  constructor(props) {
    super(props);
    const { product } = props;
    this.productStandardQuantities = product.isPartitionedBy;

    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderPNQLine = this.renderPNQLine.bind(this);
    this.renderPnqLabel = this.renderPnqLabel.bind(this);
    this.renderPnqCell = this.renderPnqCell.bind(this);

    const { formatedPNQ, availablePNQs } = NutritionTab.formatPSQ(
      this.productStandardQuantities
    );

    this.formatedPNQ = formatedPNQ;
    this.availablePNQs = availablePNQs;
  }

  renderPSQHeader(psq) {
    const psqName = extractDeclinableByCode(psq.name, contentLanguage);
    let psqNameFallback = null;
    if (!psqName || !psqName.data) {
      const psqNormalizedQuantity =
        psq.quantity && psq.quantity.length ? psq.quantity[0] : null;
      psqNameFallback = psqNormalizedQuantity
        ? `${psqNormalizedQuantity.data}${psqNormalizedQuantity.expressedIn.code}`
        : null;
    }
    return (
      <th
        key={psqName.data || psqNameFallback}
        colSpan={2}
        className="NutritionTable__headerCell"
      >
        {psqName.data || psqNameFallback}
      </th>
    );
  }

  renderMeasurementCell(psqLabel, pnqCode, pnqData) {
    const measurementPrecisionCode =
      pnqData && pnqData.quantityMeasurementPrecision
        ? pnqData.quantityMeasurementPrecision.code
        : '';
    const measurementPrecision =
      measurementPrecisionCode && measurementPrecisionCode !== '='
        ? measurementPrecisionCode
        : '';

    const measurementLabel =
      pnqData && pnqData.quantity && pnqData.quantity.length
        ? `${measurementPrecision}${pnqData.quantity[0].data} ${pnqData.quantity[0].expressedIn.code}`
        : i18n.t('frontproductpage.nutrition.measure_not_available.label', {
            defaultValue: 'N/A',
          });
    const measurementCell = (
      <td
        key={`${psqLabel}-${pnqCode}-quantity`}
        className="NutritionTable__cell"
      >
        {measurementLabel}
      </td>
    );

    return measurementCell;
  }

  renderDailyIntakeCell(psqLabel, pnqCode, pnqData) {
    const percentageDVIMeasurementPrecisionCode =
      pnqData && pnqData.percentageDVIMeasurementPrecision
        ? pnqData.percentageDVIMeasurementPrecision.code
        : '';
    const percentageDVIMeasurementPrecision =
      percentageDVIMeasurementPrecisionCode !== '='
        ? percentageDVIMeasurementPrecisionCode
        : '';
    const dailyIntakeLabel =
      pnqData && pnqData.percentageOfDailyValueIntake
        ? `${percentageDVIMeasurementPrecision}${
            pnqData.percentageOfDailyValueIntake
          }${i18n.t(
            'frontproductpage.nutrition.percentage_daily_value_intake.label',
            { defaultValue: '%DV' }
          )}`
        : '';
    const dailyIntakeCell = (
      <td
        key={`${psqLabel}-${pnqCode}-dailyIntake`}
        className="NutritionTable__cell"
      >
        {dailyIntakeLabel}
      </td>
    );

    return dailyIntakeCell;
  }

  renderPnqCell(psq, pnq) {
    const psqName = extractDeclinableByCode(psq.name, contentLanguage);
    const pnqData = this.formatedPNQ[pnq.code][psqName.data];

    const measurementCell = this.renderMeasurementCell(
      psqName.data,
      pnq.code,
      pnqData
    );
    const dailyIntakeCell = this.renderDailyIntakeCell(
      psqName.data,
      pnq.code,
      pnqData
    );

    return [measurementCell, dailyIntakeCell];
  }

  renderPnqLabel(pnq) {
    const indentLabel = pnqCodeToIndent[pnq.code]
      ? ` - ${i18n.t('frontproductpage.nutrition.including_pnq.label', {
          defaultValue: 'including',
        })}`
      : '';

    return (
      <td className="NutritionTable__cell">{`${indentLabel} ${pnq.label}`}</td>
    );
  }

  renderPNQLine(pnq, productStandardQuantities) {
    return (
      <tr key={pnq.code}>
        {this.renderPnqLabel(pnq)}
        {productStandardQuantities.map((psq) => this.renderPnqCell(psq, pnq))}
      </tr>
    );
  }

  renderBody(productStandardQuantities) {
    return (
      <tbody>
        {this.availablePNQs.map((pnq) =>
          this.renderPNQLine(pnq, productStandardQuantities)
        )}
      </tbody>
    );
  }

  renderHeader(productStandardQuantities) {
    return (
      <thead>
        <tr className="NutritionTable__header">
          <th className="NutritionTable__headerCell">
            {i18n.t('frontproductpage.nutrition.nutritional_info.header', {
              defaultValue: 'Nutritional Information',
            })}
          </th>
          {productStandardQuantities.map((psq) => this.renderPSQHeader(psq))}
        </tr>
      </thead>
    );
  }

  render() {
    if (
      !this.productStandardQuantities ||
      !this.productStandardQuantities.length
    ) {
      return null;
    }

    return (
      <div className="Nutrition">
        <h3>
          {i18n.t('frontproductpage.nutrition.nutritional_declaration.title', {
            defaultValue: 'Nutritional declaration',
          })}
        </h3>
        <table className="NutritionTable">
          {this.renderHeader(this.productStandardQuantities)}
          {this.renderBody(this.productStandardQuantities)}
        </table>
      </div>
    );
  }
}

NutritionTab.propTypes = {
  product: productPropTypes.isRequired,
};

export default NutritionTab;
