/* eslint-disable @typescript-eslint/ban-ts-comment */
import { datadogRum } from '@datadog/browser-rum';

if (window.appconfig.datadog?.enabled) {
  datadogRum.init({
    ...window.appconfig.datadog,
    version: process.env.REACT_APP_DATADOG_APP_VERSION,
  });
} else if (process.env.REACT_APP_DATADOG_ENABLED === 'true') {
  datadogRum.init({
    // @ts-ignore
    applicationId: process.env.REACT_APP_DATADOG_APP_ID,
    // @ts-ignore
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: 'dev',
    trackUserInteractions:
      process.env.REACT_APP_DATADOG_TRACK_INTERACTIONS === 'true',
    trackFrustrations:
      process.env.REACT_APP_DATADOG_TRACK_FRUSTRATIONS === 'true',
    trackResources: process.env.REACT_APP_DATADOG_TRACK_RESOURCES === 'true',
    trackLongTasks: process.env.REACT_APP_DATADOG_TRACK_LONG_TASKS === 'true',
    // @ts-ignore
    defaultPrivacyLevel: process.env.REACT_APP_DATADOG_PRIVACY_LEVEL,
    sessionSampleRate: Number(
      process.env.REACT_APP_DATADOG_SESSION_SAMPLE_RATE
    ),
    sessionReplaySampleRate: Number(
      process.env.REACT_APP_DATADOG_SESSION_REPLAY_SAMPLE_RATE
    ),
    allowedTracingUrls: (process.env.REACT_APP_DATADOG_TRACE_URLS || '').split(
      / *, */
    ),
    traceSampleRate: Number(process.env.REACT_APP_DATADOG_TRACE_SAMPLE_RATE),
    telemetrySampleRate: 0,
    trackSessionAcrossSubdomains: false,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: false,
    version: process.env.REACT_APP_DATADOG_APP_VERSION,
  });
}
