import React from 'react';

const IconVideo = (props) => (
  <svg width={100} height={100} viewBox="0 0 32 32" {...props}>
    <path d="M16 30.6c-7.6 0-13.8-6.2-13.8-13.8C2.3 9.2 8.4 3 16 3c7.6 0 13.8 6.2 13.8 13.8 0 7.6-6.2 13.8-13.8 13.8zm0-25.5c-6.4 0-11.7 5.2-11.7 11.7S9.6 28.4 16 28.4s11.7-5.2 11.7-11.7S22.5 5.1 16 5.1z" />
    <path d="M13.1 23.1c-1.1 0-1.9-.9-1.9-2.2v-8.3c0-1.3.8-2.2 1.9-2.2.4 0 .8.1 1.2.4l7.2 4.1c.7.4 1.2 1.1 1.2 1.8s-.4 1.4-1.2 1.8l-7.2 4.1c-.4.3-.8.5-1.2.5zm0-10.8V20.9c0 .2 0 .3.1.3 0 0 .1 0 .3-.1l7.2-4.1c.2-.1.3-.2.3-.2s-.1-.1-.3-.2l-7.2-4.1c-.3-.2-.4-.2-.4-.2z" />
  </svg>
);

export default IconVideo;
