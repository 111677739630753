import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from '../../utils/DOMPurify';

import { KIND_FLOAT, KIND_LIST, KIND_TEXT } from '../../constants';
import iconMapping from '../../constants/iconMapping';
import {
  extractDeclinableByCode,
  isDataEmpty,
  createLineBreak,
} from '../../utils';
import { contentLanguage } from '../../utils/i18n';

import './index.scss';

const Field = function ({ field, product }) {
  if (isDataEmpty(product, field)) {
    return null;
  }

  let icon = null;
  let prefix = null;
  let suffix = null;
  let fieldTextClassName = 'FieldText';

  if (field.options && field.options.icon) {
    icon = iconMapping[field.options.icon] ? (
      <span className="Field__Icon">
        {iconMapping[field.options.icon](product)}
      </span>
    ) : null;
    fieldTextClassName += ' FieldText--withIcon';
  }
  if (field.options && field.options.prefix) {
    ({ prefix } = field.options);
  }
  if (field.options && field.options.suffix) {
    ({ suffix } = field.options);
  }

  if (field.options && field.options.pictureUrl) {
    return (
      <div>
        <img
          className="FieldText--pictureIcon"
          src={product[field.model].pictureUrl}
          alt={product[field.model].label}
        />{' '}
        {product[field.model].label}
      </div>
    );
  }

  if (field.inputKind.kind === KIND_TEXT) {
    const fieldData = extractDeclinableByCode(
      product[field.model],
      contentLanguage
    );
    const dataToDisplay = `${prefix || ''}${createLineBreak(fieldData.data)}${
      suffix || ''
    }`;

    return (
      <div
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dataToDisplay) }}
      />
    );
  }

  if (field.inputKind.kind === KIND_FLOAT) {
    const fieldData = product[field.model];

    return (
      <div className="Field">
        {icon}
        <span className={fieldTextClassName}>
          {prefix}
          {fieldData}
          {suffix}
        </span>
      </div>
    );
  }

  if (field.inputKind.kind === KIND_LIST) {
    return (
      <div>
        {product[field.model].map((subProduct, i) =>
          field.children.map((item, j) => (
            // eslint-disable-next-line react/no-array-index-key
            <Field key={`${i}-${j}`} product={subProduct} field={item} />
          ))
        )}
      </div>
    );
  }

  return null;
};

Field.propTypes = {
  product: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
};

export default Field;
