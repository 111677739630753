import React from 'react';

const IconTasting = (props) => (
  <svg viewBox="0 0 32 32" width={100} height={100} {...props}>
    <path d="M11.4 26.2c-3-.2-5-1.2-5.9-3.1-2.5-5 3.9-14.6 5.9-17.3l.7-.9.7.9c2 2.8 8.4 12.3 5.9 17.3-.9 1.8-2.9 2.9-5.8 3H12.1l-.1-.7-.1.7-.5.1zM12.1 7C8.5 12.2 5 19.1 6.7 22.5c.7 1.5 2.5 2.3 5.3 2.3h.2c2.8-.1 4.6-.8 5.3-2.3 1.8-3.4-1.7-10.3-5.4-15.5z" />
    <circle fill="#FFF" cx="15.7" cy="17.2" r="6.1" />
    <path d="M15.7 23.8c-1.4 0-2.7-.4-3.8-1.2-3-2.1-3.7-6.3-1.6-9.2 1.2-1.8 3.3-2.8 5.4-2.8 1.4 0 2.7.4 3.8 1.2 1.4 1 2.4 2.5 2.7 4.3.3 1.7-.1 3.5-1.1 4.9-1.2 1.8-3.2 2.8-5.4 2.8zm.1-12.1c-1.8 0-3.5.9-4.5 2.3-1.8 2.5-1.2 5.9 1.3 7.7.9.7 2 1 3.2 1 1.8 0 3.5-.9 4.5-2.3.9-1.2 1.2-2.7.9-4.1-.2-1.5-1-2.7-2.3-3.6-.9-.7-2-1-3.1-1z" />
    <path d="M28.3 27.8L21 22.6c-.6-.4-.8-1.3-.3-1.9.4-.6 1.3-.8 1.9-.3l7.3 5.1c.6.4.8 1.3.3 1.9-.4.7-1.3.8-1.9.4zM12.1 17.8h-.5c0-3.6 2.7-4.5 2.7-4.5l.2.5c-.2.1-2.4.8-2.4 4zM18 8.8c-.9 0-1.7-.8-1.7-1.7s.8-1.7 1.7-1.7c.9 0 1.7.8 1.7 1.7s-.8 1.7-1.7 1.7zm0-2.4c-.4 0-.8.4-.8.8s.4.8.8.8.8-.4.8-.8-.3-.8-.8-.8zM3.5 13.3c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2 2.2 1 2.2 2.2-1 2.2-2.2 2.2zm0-3.2c-.6 0-1 .5-1 1s.5 1 1 1 1-.5 1-1-.5-1-1-1z" />
  </svg>
);

export default IconTasting;
