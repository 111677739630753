import React from 'react';
import PropTypes from 'prop-types';

import { productPropTypes } from './utils/propTypes/product';
import Header from './layout/header';
import Tabs from './layout/tabs';
import LegalInformation from './layout/legalInformation';

import './App.scss';

const App = function ({ configuration, product }) {
  const { theme } = configuration;
  document.body.classList.add(configuration.organization.name);
  Object.keys(theme).forEach((prop) => {
    document.documentElement.style.setProperty(prop, theme[prop]);
  });

  return (
    <div className={`App ${configuration.organization.name}`}>
      <Header product={product} configuration={configuration} />
      <Tabs product={product} configuration={configuration} />
      <LegalInformation product={product} />
    </div>
  );
};

App.propTypes = {
  configuration: PropTypes.shape({
    theme: PropTypes.shape({}),
    organization: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  product: productPropTypes.isRequired,
};

export default App;
