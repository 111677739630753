import React from 'react';
import DOMPurify from '../../utils/DOMPurify';
import i18n, { contentLanguage } from '../../utils/i18n';
import HeaderTitle from '../headerTitle';
import { productPropTypes } from '../../utils/propTypes/product';
import { createLineBreak, extractDeclinableByCode } from '../../utils';

const Description = function ({ product }) {
  const description = extractDeclinableByCode(
    product.description,
    contentLanguage
  );
  if (!description || !description.data) {
    return null;
  }
  const dataToDisplay = createLineBreak(description.data);

  return (
    <div className="">
      <HeaderTitle
        title={i18n.t('frontproductpage.description.header.title', {
          defaultValue: 'Description',
        })}
      />
      <p
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dataToDisplay) }}
      />
    </div>
  );
};

Description.propTypes = {
  product: productPropTypes.isRequired,
};

export default Description;
