import React from 'react';

const IconAdvices = (props) => (
  <svg width={100} height={100} viewBox="0 0 32 32" {...props}>
    <path d="M28.326 23.653a16.618 16.618 0 0 0-6.982-.712 10.33 10.33 0 0 1 3.423-.576c1.017 0 1.66.135 1.66.135.272.068.543 0 .78-.17a.954.954 0 0 0 .34-.71V8.841c.27.237.541.475.813.746v14.065zm-24.47 0V9.622C5.45 8.13 7.211 7.385 9.11 7.385c2.847 0 5.253 1.66 6.1 2.27v14.336c-1.999-.779-4.066-1.152-6.168-1.152-2.27 0-4.066.44-5.185.814zm13.184-14.1c.678-.711 3.118-2.914 7.287-2.914.44 0 .915.034 1.39.068v13.93c-.272-.035-.611-.035-.984-.035-1.728 0-4.778.373-7.693 2.678V9.554zm12.81-.948a11.347 11.347 0 0 0-2.338-1.796V5.96a.878.878 0 0 0-.745-.88 14.819 14.819 0 0 0-2.44-.204c-4.406 0-7.185 2.135-8.304 3.22-1.152-.848-3.796-2.475-6.948-2.475-2.508 0-4.778 1.017-6.778 3.017a.926.926 0 0 0-.271.644v15.725c0 .305.17.61.44.746.272.17.577.17.882.034.034 0 2.304-1.119 5.66-1.119 2.304 0 4.541.509 6.71 1.525.068.034.102.034.17.068h.033c.068 0 .102.034.17.034h.135a.81.81 0 0 0 .475-.17 15.496 15.496 0 0 1 6.473-1.423c3.355 0 5.626 1.119 5.66 1.119.271.135.61.135.881-.034.271-.17.44-.44.44-.746V9.317c-.033-.34-.135-.543-.304-.712z" />
    <path d="M10.262 17.755H8.906v-.338c0-.441.068-.78.203-1.085.136-.271.44-.61.848-.949.44-.339.677-.576.78-.678a.961.961 0 0 0-.136-1.322c-.238-.203-.543-.305-.916-.305-.372 0-.677.102-.949.305-.27.204-.44.543-.508.983l-1.356-.17c.034-.61.305-1.151.814-1.592.508-.44 1.152-.644 1.966-.644.847 0 1.525.237 2.033.678.508.44.746.949.746 1.559 0 .339-.102.644-.271.915-.17.305-.577.678-1.187 1.186-.305.271-.508.475-.576.61-.135.136-.17.44-.135.847zm-1.356 1.966v-1.457h1.491v1.491H8.906z" />
  </svg>
);

export default IconAdvices;
