const primaryColor = '#352a26';

const casinoTheme = {
  '--background-color': '#ffffff',
  '--primary-color': primaryColor,
  '--secondary-color': '#b8b8b8',

  '--font-family': 'Helvetica,Arial,sans-serif',
  '--font-size': '11px',

  '--viewport-height': '100%',

  '--header-border-bottom-color': primaryColor,
  '--header-border-bottom-style': 'solid',
  '--header-border-bottom-width': '1px',
  '--header-border-top-width': '0',
  '--header-text-transform': 'uppercase',

  '--header-color': primaryColor,
  '--header-font-size': '14px',
  '--header-font-weight': '400',
  '--header-padding-bottom': '5px',

  '--allergens-font-size': '14px',
  '--contact-font-size': '14px',

  '--tabs-border-top-width': '1px',
};

export default casinoTheme;
