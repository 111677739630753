import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { productPropTypes } from '../../utils/propTypes/product';
import HeaderTitle from '../headerTitle';

import './index.scss';

const Labels = function ({ product, options }) {
  if (
    !product ||
    ((!product.isLabeledBy || !product.isLabeledBy.length) &&
      (!product.hazardPictograms || !product.hazardPictograms.length))
  ) {
    return null;
  }

  let allLabels = product.isLabeledBy || [];
  if (product.hazardPictograms && product.hazardPictograms.length) {
    allLabels = product.hazardPictograms.concat(allLabels);
  }
  const labels = allLabels.slice(0, 10);

  return (
    <div className="Labels">
      {options.displayLabelsTitle ? <HeaderTitle title="Labels" /> : null}
      <div className="Labels__wrapper">
        {labels.map((label) => (
          <div
            key={label.isConceptualizedBy.code}
            className="Label"
            style={{
              backgroundImage: `url(${label.isConceptualizedBy.pictureUrl})`,
            }}
            data-tip={label.isConceptualizedBy.label}
          />
        ))}
        <ReactTooltip effect="solid" />
      </div>
    </div>
  );
};

Labels.propTypes = {
  product: productPropTypes,
  options: PropTypes.shape({
    displayLabelsTitle: PropTypes.bool,
  }),
};

Labels.defaultProps = {
  options: {
    displayLabelsTitle: true,
  },
};

export default Labels;
