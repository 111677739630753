import i18n from '../../../utils/i18n';

export const mangerBougerLabel = [
  i18n.t('frontproductpage.legal_info.eat_fruits_vegetables.label', {
    defaultValue:
      'For your health, eat at least 5 fruits and vegetables per day. More informations on www.mangerbouger.fr',
  }),
  i18n.t('frontproductpage.legal_info.eating_too_fat.label', {
    defaultValue:
      'For your health, do not eat too sweat, too salty, too fat. More informations on www.mangerbouger.fr',
  }),
  i18n.t('frontproductpage.legal_info.avoid_snacking.label', {
    defaultValue:
      'For your health, avoid snacking between meals. More informations on www.mangerbouger.fr',
  }),
  i18n.t('frontproductpage.legal_info.physical_activity.label', {
    defaultValue:
      'For your health, practice regular physical activity. More informations on www.mangerbouger.fr',
  }),
];
export const mangerBougerChildren = [
  i18n.t('frontproductpage.legal_info_children.avoid_snacking.label', {
    defaultValue: 'Teach your child not to snack between meals',
  }),
  i18n.t('frontproductpage.legal_info_children.physical_activity.label', {
    defaultValue: 'Move, play is essential to the development of your child',
  }),
];

export const alcoholLabel = [
  i18n.t('frontproductpage.legal_info.alcohol_abuse.label', {
    defaultValue:
      'Alcohol abuse is dangerous for health. To consume with moderation. The consumption of alcoholic beverages during pregnancy, even in small amounts, can have serious consequences on the health of the child.',
  }),
];

export const dangerLabel = [
  i18n.t('frontproductpage.legal_info.dangerous.label', {
    defaultValue: 'Dangerous, respect precautions. ',
  }),
];

export const biocideLabel = [
  i18n.t('frontproductpage.legal_info.biocides.label', {
    defaultValue:
      'Use biocides carefully. Before use, read the label and product information.',
  }),
];

export const energyLabel = [
  i18n.t('frontproductpage.legal_info.energy.label', {
    defaultValue: 'Energy is our future, save it.',
  }),
];
