import React from 'react';

import i18n, { contentLanguage } from '../../utils/i18n';
import HeaderTitle from '../headerTitle';
import { productPropTypes } from '../../utils/propTypes/product';
import { extractDeclinableByCode } from '../../utils';

const NameLegal = function ({ product }) {
  const nameLegal = extractDeclinableByCode(product.nameLegal, contentLanguage);
  const netContent = extractDeclinableByCode(product.netContent);
  if ((!nameLegal || !nameLegal.data) && (!netContent || !netContent.data)) {
    return null;
  }

  const netContentElement =
    netContent && netContent.expressedIn ? (
      <p>
        {i18n.t('frontproductpage.product.quantity.label', {
          defaultValue: 'Net quantity of:',
        })}{' '}
        {netContent.data}
        {netContent.expressedIn.code}
      </p>
    ) : null;

  return (
    <div className="">
      <HeaderTitle
        title={i18n.t('frontproductpage.product.header.title', {
          defaultValue: 'The product',
        })}
      />
      <p>{nameLegal.data}</p>
      {netContentElement}
    </div>
  );
};

NameLegal.propTypes = {
  product: productPropTypes.isRequired,
};

export default NameLegal;
