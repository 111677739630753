import * as i18next from 'i18next';
import resources from '../locales';

import { getUrlParams } from '../core/fetch';

const urlParams = getUrlParams(
  window.location.search || window.location.hash.slice(2)
);

const SUPPORTED_LANGUAGES = ['fr', 'en', 'de', 'es', 'pt'];
const DEFAULT_LANGUAGE = 'fr';

function getUserLanguage() {
  const language =
    (navigator.languages &&
      navigator.languages.length &&
      navigator.languages[0]) ||
    navigator.language;
  ('');

  if (SUPPORTED_LANGUAGES.includes(language.substring(0, 2))) {
    return language;
  }

  return DEFAULT_LANGUAGE;
}

function getAppLanguage() {
  if (process.env.NODE_ENV === 'test') {
    return DEFAULT_LANGUAGE;
  }
  if (
    urlParams.language &&
    SUPPORTED_LANGUAGES.includes(urlParams.language.substring(0, 2))
  ) {
    return urlParams.language.substring(0, 2);
  }
  // Add organization mapping?

  return getUserLanguage();
}

export const contentLanguage = getAppLanguage();

/**
 * @type {import('i18next').InitOptions}
 */
const options = {
  lng: contentLanguage,
  fallbackLng: 'en',
  returnEmptyString: false,
  nsSeparator: '__',
  keySeparator: '>>>',
  contextSeparator: ':::',
  resources,
  compatibilityJSON: 'v3',
};

i18next.init(options);

export default i18next;
