import React from 'react';
import PropTypes from 'prop-types';

import { productPropTypes } from '../../utils/propTypes/product';

import Allergens from '../../components/allergens';
import BrandPicture from '../../components/brandPicture';
import Description from '../../components/description';
import Labels from '../../components/labels';
import Manufacturer from '../../components/manufacturer';
import NameLegal from '../../components/nameLegal';
import Nutriscore from '../../components/nutriscore';
import Origin from '../../components/origin';
import ProductPictures from '../../components/productPictures';

import './index.scss';

const Header = function ({ product, configuration }) {
  const getPictureElement = function (type) {
    switch (type) {
      case 'ProductPictures':
        return <ProductPictures product={product} />;
      case 'BrandPicture':
        return <BrandPicture product={product} />;
      default:
        return null;
    }
  };

  return (
    <header className="Header">
      <div className="Header__column Header__column--left">
        <div className="Header__mainPicture">
          {getPictureElement(configuration.options.mainPicture)}
        </div>
        <div className="Header__secondaryPictureAndLabels">
          {getPictureElement(configuration.options.secondaryPicture)}
          <Labels product={product} options={configuration.options} />
        </div>
        <div>
          <Nutriscore product={product} />
        </div>
        <div>
          <Allergens product={product} />
        </div>
      </div>
      <div className="Header__column Header__column--right">
        <NameLegal product={product} />
        <Description product={product} />
        <Origin product={product} />
        <Manufacturer product={product} />
      </div>
    </header>
  );
};

Header.propTypes = {
  product: productPropTypes.isRequired,
  configuration: PropTypes.shape({
    options: PropTypes.object.isRequired,
  }).isRequired,
};

export default Header;
