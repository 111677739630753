import React from 'react';

import i18n, { contentLanguage } from '../../utils/i18n';
import HeaderTitle from '../headerTitle';
import { productPropTypes } from '../../utils/propTypes/product';
import { extractDeclinableByCode } from '../../utils';

const Origin = function ({ product }) {
  const origin = extractDeclinableByCode(product.originText, contentLanguage);
  if (!origin || !origin.data) {
    return null;
  }

  return (
    <div className="">
      <HeaderTitle
        title={i18n.t('frontproductpage.origin.header.title', {
          defaultValue: 'Origin',
        })}
      />
      <p>{origin.data}</p>
    </div>
  );
};

Origin.propTypes = {
  product: productPropTypes.isRequired,
};

export default Origin;
