import React from 'react';
import PropTypes from 'prop-types';

import { tabPropTypes } from '../../utils/propTypes/tab';

import IconAdvices from '../../assets/icons/IconAdvices';
import IconBenefits from '../../assets/icons/IconBenefits';
import IconComposition from '../../assets/icons/IconComposition';
import IconConservation from '../../assets/icons/IconConservation';
import IconExpertise from '../../assets/icons/IconExpertise';
import IconForYou from '../../assets/icons/IconForYou';
import IconGeneral from '../../assets/icons/IconGeneral';
import IconNutrition from '../../assets/icons/IconNutrition';
import IconNutritionpetfood from '../../assets/icons/IconNutritionpetfood';
import IconRecipes from '../../assets/icons/IconRecipes';
import IconRitual from '../../assets/icons/IconRitual';
import IconTasting from '../../assets/icons/IconTasting';
import IconTips from '../../assets/icons/IconTips';
import IconVideo from '../../assets/icons/IconVideo';

import './index.scss';

const iconMapping = {
  advices: <IconAdvices className="TabIcon" />,
  benefits: <IconBenefits className="TabIcon" />,
  composition: <IconComposition className="TabIcon" />,
  conservation: <IconConservation className="TabIcon" />,
  expertise: <IconExpertise className="TabIcon" />,
  'for-you': <IconForYou className="TabIcon" />,
  general: <IconGeneral className="TabIcon" />,
  nutrition: <IconNutrition className="TabIcon" />,
  nutritionpetfood: <IconNutritionpetfood className="TabIcon" />,
  recipes: <IconRecipes className="TabIcon" />,
  ritual: <IconRitual className="TabIcon" />,
  tasting: <IconTasting className="TabIcon" />,
  tips: <IconTips className="TabIcon" />,
  video: <IconVideo className="TabIcon" />,
};

const Tab = function ({ tab, active }) {
  let className = 'Tab';
  if (active) {
    className += ' Tab--active';
  }

  return (
    <div className={className}>
      {iconMapping[tab.id]}
      <div className="Tab__label">{tab.name}</div>
    </div>
  );
};

Tab.propTypes = {
  tab: tabPropTypes.isRequired,
  active: PropTypes.bool.isRequired,
};

export default Tab;
