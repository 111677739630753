import React from 'react';

const IconGeneral = (props) => (
  <svg width="337.728" height="345.54" viewBox="0 0 337.728 345.54" {...props}>
    <path
      fill="#12100B"
      d="M170.685 20.326L20.536 170.775l-.455 154.438h103.355v-17.718H37.8l-.012-126.559.121-2.491L170.685 45.404l132.781 133.053.104 129.038h-76.777v-38.39c0-25.203-16.715-46.578-39.641-53.631l7.543-7.539-12.527-12.527-29.951 29.946 29.951 29.945 12.527-12.527-8.985-8.985c13.721 5.86 23.364 19.483 23.364 35.32v56.108h112.215l-.012-145.118-.444-9.32L170.685 20.326"
    />
  </svg>
);

export default IconGeneral;
