import React from 'react';

const IconConservation = (props) => (
  <svg viewBox="0 0 32 32" width={100} height={100} {...props}>
    <path d="M15 29.2c-3.3 0-6.1-2.7-6.1-6 0-2.3 1.3-4.3 3.3-5.3l.5-.2V5.2c0-1.4 1.1-2.5 2.4-2.5.6 0 1.3.3 1.7.7.5.5.7 1.1.7 1.7v12.5l.4.3c1.8 1.1 3 3.1 3 5.2.1 3.4-2.6 6.1-5.9 6.1zm4.3-12.5V5.2c0-1.1-.4-2.2-1.2-3-.8-.8-1.9-1.2-3-1.2C12.8 1 11 2.9 11 5.2v11.3c-2.3 1.4-3.7 3.9-3.7 6.6 0 4.3 3.5 7.8 7.8 7.8s7.8-3.5 7.8-7.8c-.1-2.6-1.4-5-3.6-6.4z" />
    <path d="M16 19.8V9.2c0-.5-.4-.9-.9-.9s-.9.4-.9.9v10.5c-1.6.3-2.7 1.7-2.7 3.4 0 1.9 1.6 3.5 3.5 3.5s3.5-1.6 3.5-3.5c0-1.5-1.1-2.8-2.5-3.3zM24.7 7.7h-3.2c-.5 0-.9.4-.9.9s.4.9.9.9h3.2c.5 0 .9-.4.9-.9s-.4-.9-.9-.9zM24.7 4.8h-3.2c-.5 0-.9.4-.9.9s.4.9.9.9h3.2c.5 0 .9-.4.9-.9s-.4-.9-.9-.9zM24.7 10.4h-3.2c-.5 0-.9.4-.9.9s.4.9.9.9h3.2c.5 0 .9-.4.9-.9s-.4-.9-.9-.9zM24.7 13.2h-3.2c-.5 0-.9.4-.9.9s.4.9.9.9h3.2c.5 0 .9-.4.9-.9s-.4-.9-.9-.9z" />
  </svg>
);

export default IconConservation;
