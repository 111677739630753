import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const HeaderTitle = function ({ title }) {
  return <h2 className="HeaderTitle">{title}</h2>;
};

HeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default HeaderTitle;
