import React from 'react';
import DOMPurify from '../../utils/DOMPurify';

import { productPropTypes } from '../../utils/propTypes/product';

function VideoTab({ product }) {
  const { videos } = product.assets || {};
  if (!videos || !videos.length) {
    return null;
  }

  const videoId = videos[0].uniformResourceIdentifier;
  if (!videoId) {
    return null;
  }

  const styleUrl =
    'https://cvc.supplierxm.salsify.com/plugins/playertv/css/player.css';
  const { document } = window;
  /**
   * @type {any}
   */
  const doc = document;
  if (doc.createStyleSheet) {
    doc.createStyleSheet(styleUrl);
  } else {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = styleUrl;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  const videoHtml =
    '<div class="bs-player"' +
    'style="width:512px; height:288px; margin:auto; max-width:100%;"' +
    `data-bs-publisherid="${videoId}"` +
    'data-bs-playerparamsurl="https://services.cvc.supplierxm.salsify.com/smartplayerconfig-6403c4a892fffd1da19c.html"' +
    'data-bs-playerguid="f55f195beb315e"' +
    'data-bs-autostart="1">' +
    '</div>';

  const js = document.createElement('script');
  js.type = 'text/javascript';
  js.async = true;
  js.src =
    'https://playermanager-cdn.wedia-group.com/configuration/f55f195beb315e/launcher/config.js';
  const s = document.getElementsByTagName('script')[0];
  if (s.parentNode) {
    s.parentNode.insertBefore(js, s);
  }

  return (
    <div className="Tab">
      <div
        className="Video__content"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(videoHtml) }}
      />
    </div>
  );
}

VideoTab.propTypes = {
  product: productPropTypes.isRequired,
};

export default VideoTab;
