export const pnqCodeToIndent = {
  saturated_fat: true,
  monounsaturated_fat: true,
  polyunsaturated_fat: true,
  trans_fat: true,
  sugars: true,
  polyols: true,
  starch: true,
};

export const pnqCodeSorted = [
  'food_energy_kJ',
  'food_energy_kcal',
  'fat',
  'saturated_fat',
  'monounsaturated_fat',
  'polyunsaturated_fat',
  'carbohydrates',
  'sugars',
  'polyols',
  'starch',
  'dietary_fibres',
  'proteins',
  'salt',
  'sulfate',
];
