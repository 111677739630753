import { contentLanguage } from './i18n';
import { KIND_DICT, KIND_FLOAT, KIND_LIST, KIND_TEXT } from '../constants';

export const extractDeclinableBy = function (items, type, code) {
  if (!items || !items.length || !type) {
    return {};
  }

  const itemFound =
    items.find((item, index) => {
      if (code) {
        const itemCode = item[type].normalizedCode || item[type].code;
        return itemCode === code;
      }
      return index === 0;
    }) || {};

  return itemFound;
};

export const extractDeclinableByCode = function (items, expressedInCode) {
  return extractDeclinableBy(items, 'expressedIn', expressedInCode);
};

export const extractDeclinableByType = function (items, typeCode) {
  return extractDeclinableBy(items, 'type', typeCode);
};

export const isDataEmpty = function (product, field) {
  if (!field || !field.inputKind || !field.inputKind.kind) {
    return true;
  }
  if (field.inputKind.kind === KIND_TEXT && field.declinableBy) {
    if (field.declinableBy.kind === 'languages') {
      return !extractDeclinableByCode(product[field.model], contentLanguage)
        .data;
    }
    return !product[field.model] || !product[field.model].length;
  }

  if (
    field.inputKind.kind === KIND_TEXT ||
    field.inputKind.kind === KIND_FLOAT
  ) {
    return !product[field.model];
  }

  if (field.inputKind.kind === KIND_LIST) {
    return (
      !product[field.model] ||
      !product[field.model].length ||
      !!product[field.model].every(
        (subProduct) =>
          field.children &&
          field.children.some((subField) => isDataEmpty(subProduct, subField))
      )
    );
  }

  if (field.inputKind.kind === KIND_DICT) {
    return (
      !product[field.model] ||
      field.children.some((subField) =>
        isDataEmpty(product[field.model], subField)
      )
    );
  }

  return true;
};

export const isTabAvailable = function (product, tab) {
  let isAvailable = false;
  if (!tab || !tab.content || !tab.content.length) {
    return isAvailable;
  }

  isAvailable = tab.content.reduce((localIsAvailable, block) => {
    const isBlockAvailable = block.fields.reduce(
      (someFieldAvailable, field) =>
        someFieldAvailable || !isDataEmpty(product, field),
      false
    );
    return localIsAvailable || isBlockAvailable;
  }, isAvailable);

  return isAvailable;
};

export const createLineBreak = (fieldContent) => {
  if (typeof fieldContent.replace === 'function') {
    return fieldContent.replace(/([^&])(#|\n)/g, (_, $1) => `${$1}<br/>`);
  }
  return fieldContent;
};
