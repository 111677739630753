import React from 'react';

import { productPropTypes } from '../../utils/propTypes/product';
import { tabPropTypes } from '../../utils/propTypes/tab';
import EnrichedContentTab from '../../components/tabs/enrichedContentTab';
import VideoTab from '../../components/tabs/videoTab';
import NutritionTab from '../../components/tabs/nutrition/nutritionTab';
import GenericTab from '../../components/tabs/genericTab';

// const TabContent = function({ product, tab }) {
class TabContent extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.tab.id !== nextProps.tab.id;
  }

  render() {
    const { product, tab } = this.props;

    if (!tab || !tab.type) {
      return null;
    }
    if (tab.type === 'specific') {
      switch (tab.id) {
        case 'recipes':
          return <EnrichedContentTab product={product} />;
        case 'video':
          return <VideoTab product={product} />;
        case 'nutrition':
          return <NutritionTab product={product} />;
        default:
          return null;
      }
    }

    return <GenericTab product={product} tab={tab} />;
  }
}

TabContent.propTypes = {
  product: productPropTypes.isRequired,
  tab: tabPropTypes.isRequired,
};

export default TabContent;
