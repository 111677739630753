import PropTypes from 'prop-types';

export const productPropTypes = PropTypes.shape({
  id: PropTypes.number,
  _jsonVersion: PropTypes.number,
  assets: PropTypes.shape({
    pictures: PropTypes.array,
  }).isRequired,
  brand: PropTypes.shape({
    pictureUrl: PropTypes.string,
    label: PropTypes.string,
  }),
  isLabeledBy: PropTypes.array.isRequired,
});
