import React from 'react';

import { productPropTypes } from '../../utils/propTypes/product';
import { tabPropTypes } from '../../utils/propTypes/tab';
import { isDataEmpty } from '../../utils';

import Field from '../field';

class GenericTab extends React.Component {
  constructor(props) {
    super(props);
    this.renderBlock = this.renderBlock.bind(this);
  }

  renderTitle(title) {
    if (!title) {
      return null;
    }
    return <h3>{title}</h3>;
  }

  renderBlock(block, key) {
    const { product } = this.props;
    if (!block || !block.fields) {
      return null;
    }
    const displayBlock = block.fields.reduce(
      (someFieldAvailable, field) =>
        someFieldAvailable || !isDataEmpty(product, field),
      false
    );

    if (!displayBlock) {
      return null;
    }

    return (
      <div key={key}>
        {this.renderTitle(block.title)}
        {block.fields.map((field, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Field key={index} product={product} field={field} />
        ))}
      </div>
    );
  }

  render() {
    const { tab } = this.props;
    if (!tab || !tab.content) {
      return null;
    }

    return (
      <div className="Tab">
        {tab.content.map((block, index) => this.renderBlock(block, index))}
      </div>
    );
  }
}

GenericTab.propTypes = {
  product: productPropTypes.isRequired,
  tab: tabPropTypes.isRequired,
};

export default GenericTab;
