import React from 'react';

const IconExpertise = (props) => (
  <svg viewBox="-10 -5 110 110" height={100} width={100} {...props}>
    <path d="M12.7 24.4c2.7 9.3 5.4 18.5 8.1 27.8 8.3-8.6 21.6-15.9 33-12.2 4.5 1.5 9.2 6.4 14.1 6 4.9-.4 9.7-3.1 12.3-7.4 1.5-2.5 2.6-5.1 3.4-7.8C77 33.4 69 33 62.7 28.7 55.9 24 50.6 16 43.1 12.4c-12.8-6.2-24.5.2-30.4 12-.2.5-.5.9-.7 1.4.2-.5.5-1 .7-1.4zm78.1-13.5c.3 0 .5.1.8.2.1 0 .2.1.4.1 1.6.6 3.1 2 3.2 4v1.2c-2.2 13.8-5 33.7-21.7 37.5-4 .9-7.5.9-11.5-.1-5-1.2-7.9-5.9-13.4-5.8-9.6.3-19.1 6-24.5 13.9-.1.1-.2.2-.2.3C26.9 72.6 30 83 33 93.4c1.6 5.4-6.8 7.7-8.4 2.3C21 84.1 17.7 72.5 14.3 61L4.1 25.9c-.3-1-.2-1.8 0-2.5 0-.6.1-1.3.4-2C11.8 5.3 30.9-5 47.5 4.9 54.9 9.3 59.7 17 67.1 21.2c7.8 4.4 18.2 2.4 19.6-7.2.4-2.4 2.2-3.3 4.1-3.1-.1 0-.1 0 0 0z" />
    <path d="M91.9 11.2c1.7.6 3.1 2 3.2 4-.1-2-1.6-3.4-3.2-4zM90.7 10.9c-1.8-.2-3.6.7-4 3.1.4-2.4 2.2-3.3 4-3.1z" />
  </svg>
);

export default IconExpertise;
