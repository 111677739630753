import {
  alcoholLabel,
  biocideLabel,
  dangerLabel,
  energyLabel,
  mangerBougerChildren,
  mangerBougerLabel,
} from './legalMentions';

export const kindToLegalMentionsMap = {
  100753: alcoholLabel,
  100754: alcoholLabel,
  100755: mangerBougerLabel,
  100756: mangerBougerLabel,
  100757: mangerBougerLabel,
  100758: mangerBougerLabel,
  100759: mangerBougerLabel,
  100761: mangerBougerLabel,
  100762: mangerBougerLabel,
  100763: mangerBougerLabel,
  100764: mangerBougerLabel,
  100765: mangerBougerLabel,
  100766: mangerBougerLabel,
  100767: mangerBougerLabel,
  100768: mangerBougerLabel,
  100769: mangerBougerLabel,
  100770: mangerBougerLabel,
  100771: mangerBougerLabel,
  100772: mangerBougerLabel,
  100773: mangerBougerLabel,
  100774: mangerBougerLabel,
  100775: mangerBougerLabel,
  100777: mangerBougerLabel,
  100778: mangerBougerLabel,
  100779: mangerBougerLabel,
  100780: mangerBougerLabel,
  100781: mangerBougerLabel,
  100782: mangerBougerLabel,
  100783: mangerBougerLabel,
  100784: mangerBougerLabel,
  100785: mangerBougerLabel,
  100786: mangerBougerLabel,
  100787: mangerBougerLabel,
  100788: mangerBougerLabel,
  100789: mangerBougerLabel,
  100790: mangerBougerLabel,
  100791: mangerBougerLabel,
  100792: mangerBougerLabel,
  100793: mangerBougerLabel,
  100794: mangerBougerLabel,
  100795: mangerBougerLabel,
  100796: mangerBougerLabel,
  100797: mangerBougerLabel,
  100798: mangerBougerLabel,
  100804: mangerBougerLabel,
  100805: mangerBougerLabel,
  100806: mangerBougerLabel,
  100808: mangerBougerLabel,
  100809: mangerBougerLabel,
  100817: mangerBougerLabel,
  100821: dangerLabel,
  100822: dangerLabel,
  100823: dangerLabel,
  100824: mangerBougerChildren,
  100825: mangerBougerChildren,
  100826: mangerBougerChildren,
  100827: mangerBougerChildren,
  100828: mangerBougerChildren,
  100975: mangerBougerChildren,
  100977: alcoholLabel,
  100978: alcoholLabel,
  100979: alcoholLabel,
  100980: alcoholLabel,
  100982: mangerBougerLabel,
  100983: mangerBougerLabel,
  100984: mangerBougerLabel,
  100986: mangerBougerLabel,
  100989: mangerBougerLabel,
  101071: mangerBougerLabel,
  101072: mangerBougerLabel,
  101073: mangerBougerLabel,
  101075: dangerLabel,
  101076: dangerLabel,
  101085: dangerLabel,
  101099: dangerLabel,
  101100: dangerLabel,
  101270: mangerBougerLabel,
  101295: dangerLabel,
  101296: dangerLabel,
  101298: dangerLabel,
  101299: dangerLabel,
  101300: dangerLabel,
  101301: dangerLabel,
  70004: mangerBougerLabel,
  70005: mangerBougerLabel,
  70006: mangerBougerLabel,
  70007: mangerBougerLabel,
  70008: mangerBougerLabel,
  70009: mangerBougerLabel,
  70012: mangerBougerLabel,
  70019: mangerBougerLabel,
  70020: mangerBougerLabel,
  70021: mangerBougerLabel,
  70022: mangerBougerLabel,
  70023: mangerBougerLabel,
  70024: mangerBougerLabel,
  70025: alcoholLabel,
  70026: alcoholLabel,
  70027: alcoholLabel,
  71566: mangerBougerLabel,
  71568: biocideLabel.concat(dangerLabel),
  71569: biocideLabel.concat(dangerLabel),
  71570: energyLabel.concat(dangerLabel),
  71637: energyLabel.concat(dangerLabel),
  80727: mangerBougerLabel,
  80729: energyLabel.concat(biocideLabel).concat(dangerLabel),
  82908: mangerBougerChildren,
  82909: mangerBougerChildren,
  82913: mangerBougerLabel,
  82914: alcoholLabel,
  82915: alcoholLabel,
  82916: alcoholLabel,
  82918: mangerBougerLabel,
  82919: mangerBougerLabel,
  82920: mangerBougerLabel,
  82921: mangerBougerLabel,
  82922: mangerBougerLabel,
  82923: mangerBougerLabel,
  82924: mangerBougerLabel,
  82925: mangerBougerLabel,
  82926: mangerBougerLabel,
  82927: mangerBougerLabel,
  82928: mangerBougerLabel,
  82929: mangerBougerLabel,
  82931: mangerBougerLabel,
  82938: mangerBougerLabel,
  82939: mangerBougerLabel,
  82940: mangerBougerLabel,
  82941: mangerBougerLabel,
  82942: mangerBougerLabel,
  82943: mangerBougerLabel,
  82945: mangerBougerLabel,
  82946: mangerBougerLabel,
  82947: mangerBougerLabel,
  82948: mangerBougerLabel,
  82952: mangerBougerLabel,
  82962: biocideLabel.concat(dangerLabel),
  82964: energyLabel.concat(dangerLabel),
  82965: dangerLabel,
  82967: dangerLabel,
  82968: dangerLabel,
  82969: biocideLabel.concat(dangerLabel),
  82970: biocideLabel.concat(dangerLabel),
};

export default kindToLegalMentionsMap;
