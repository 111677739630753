import React from 'react';
import DOMPurify from '../../utils/DOMPurify';
import { productPropTypes } from '../../utils/propTypes/product';

function EnrichedContentTab({ product }) {
  const { enriched_contents: enrichedContents } = product.assets || {};
  if (!enrichedContents || !enrichedContents.length) {
    return null;
  }
  const enrichedContent = enrichedContents[0];

  return (
    <div className="Tab">
      <div
        className="EnrichedContent__content"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(enrichedContent.content),
        }}
      />
    </div>
  );
}

EnrichedContentTab.propTypes = {
  product: productPropTypes.isRequired,
};

export default EnrichedContentTab;
