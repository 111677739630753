import React from 'react';

const IconRitual = (props) => (
  <svg viewBox="0 0 32 32" {...props}>
    <g stroke="#000" strokeWidth=".702" strokeMiterlimit={10}>
      <path d="M10.2 20.3c-1 0-1.9-.4-2.5-1.1-.7-.7-1-1.6-1-2.5 0-2 1.6-3.6 3.6-3.6 1 0 1.9.4 2.5 1.1.7.7 1 1.6 1 2.5-.1 2-1.7 3.6-3.6 3.6zm0-6.5c-1.6 0-2.9 1.3-2.9 2.9 0 .8.3 1.5.8 2.1.5.5 1.3.9 2 .9v.3-.3c1.6 0 2.9-1.3 2.9-2.9 0-.8-.3-1.5-.8-2.1-.5-.6-1.2-.9-2-.9zM18.5 16.8h-3.9c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h3.9c.2 0 .3.1.3.3.1.2-.1.3-.3.3zM16.2 10.9l-2.7 2.7c-.1.1-.3.1-.4 0-.1-.1-.1-.3 0-.4l2.7-2.7c.1-.1.3-.1.4 0 .1 0 .1.2 0 .4zM10.3 8.3v3.9c0 .2-.1.3-.3.3-.2 0-.3-.1-.3-.3V8.3c0-.1.1-.3.3-.3.2 0 .3.2.3.3zM4.3 10.7L7 13.4c.1.1.1.3 0 .4 0 .2-.2.2-.4.1l-2.7-2.7c-.1-.1-.1-.3 0-.4.1-.2.3-.2.4-.1zM1.8 16.6h3.9c.2 0 .3.1.3.3 0 .2-.1.3-.3.3H1.8c-.2 0-.3-.1-.3-.3 0-.2.2-.3.3-.3zM4.2 22.5l2.7-2.7c.1-.1.3-.1.4 0 .1.1.1.3 0 .4l-2.7 2.7c-.1.1-.3.1-.4 0-.1-.1-.1-.3 0-.4zM10.1 25.1v-3.9c0-.2.1-.3.3-.3.2 0 .3.1.3.3v3.9c0 .2-.1.3-.3.3-.2 0-.3-.2-.3-.3zM16 22.7L13.3 20c-.1-.1-.1-.3 0-.4.1-.1.3-.1.4 0l2.7 2.7c.1.1.1.3 0 .4-.1.1-.3.1-.4 0z" />
    </g>
    <path d="M27.5 10.2c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5h.1c-2.1-1.4-3.4-3.8-3.4-6.5s1.3-5.1 3.4-6.5h-.1zm0 14.3c-4.3 0-7.8-3.5-7.8-7.8s3.5-7.8 7.8-7.8c.8 0 1.7.1 2.5.4.3.1.5.3.5.6s-.2.5-.5.6c-2.7.9-4.4 3.4-4.4 6.2s1.8 5.3 4.4 6.2c.3.1.5.3.5.6s-.2.5-.5.6c-.8.3-1.6.4-2.5.4z" />
  </svg>
);

export default IconRitual;
