import React from 'react';
import Img from 'react-image';

import { productPropTypes } from '../../utils/propTypes/product';

import './index.scss';

const BrandPicture = function ({ product }) {
  if (!product || !product.brand || product.brand.code === 'NoBrand') {
    return null;
  }

  const brandPictureUrl =
    product.brand.pictureUrl ||
    `https://smedia.productpage.io/brand/${product.brand.code}/picture/logo/original.png`;

  return (
    <Img
      src={[brandPictureUrl]}
      className="BrandPicture"
      alt={product.brand.label}
    />
  );
};

BrandPicture.propTypes = {
  product: productPropTypes,
};

export default BrandPicture;
