import React from 'react';

import { productPropTypes } from '../../utils/propTypes/product';
import i18n from '../../utils/i18n';
import './index.scss';

const validAllergenCodes = {
  SA: true, // almond
  GB: true, // barley
  SR: true, // brazilnut
  SC: true, // cashew
  BC: true, // celery
  AE: true, // egg
  AF: true, // fish
  AW: true, // gluten
  AX: true, // gluten_grain
  AP: true, // groundnut
  SH: true, // hazelnut
  GK: true, // khorasanwheat
  ML: true, // lactose
  NL: true, // lupinus
  SM: true, // macadamia
  AM: true, // milk
  UM: true, // mollusk
  BM: true, // mustard
  AN: true, // nut
  GO: true, // oat
  SP: true, // pecannut
  ST: true, // pistachio
  SQ: true, // queensland_nut
  NR: true, // rye
  AS: true, // sesame
  AC: true, // previously shellfish -> should have been crustacean
  AY: true, // soya
  GS: true, // spelt
  AU: true, // sulfite
  SW: true, // walnut
  UW: true, // wheat
};

const getAllergenLabelByCode = (code) => {
  switch (code) {
    case 'SA':
      return i18n.t('frontproductpage.allergens.almond.label', {
        defaultValue: 'SA',
      });
    case 'GB':
      return i18n.t('frontproductpage.allergens.barley.label', {
        defaultValue: 'GB',
      });
    case 'SR':
      return i18n.t('frontproductpage.allergens.brazilnut.label', {
        defaultValue: 'SR',
      });
    case 'SC':
      return i18n.t('frontproductpage.allergens.cashew.label', {
        defaultValue: 'SC',
      });
    case 'BC':
      return i18n.t('frontproductpage.allergens.celery.label', {
        defaultValue: 'BC',
      });
    case 'AE':
      return i18n.t('frontproductpage.allergens.egg.label', {
        defaultValue: 'AE',
      });
    case '':
      return i18n.t('frontproductpage.allergens.fish.label', {
        defaultValue: 'AF',
      });
    case 'AW':
      return i18n.t('frontproductpage.allergens.gluten.label', {
        defaultValue: 'AW',
      });
    case 'AX':
      return i18n.t('frontproductpage.allergens.glutengrain.label', {
        defaultValue: 'AX',
      });
    case 'AP':
      return i18n.t('frontproductpage.allergens.groundnut.label', {
        defaultValue: 'AP',
      });
    case 'SH':
      return i18n.t('frontproductpage.allergens.hazelnut.label', {
        defaultValue: 'SH',
      });
    case 'GK':
      return i18n.t('frontproductpage.allergens.khorasanwheat.label', {
        defaultValue: 'GK',
      });
    case 'ML':
      return i18n.t('frontproductpage.allergens.lactose.label', {
        defaultValue: 'ML',
      });
    case 'NL':
      return i18n.t('frontproductpage.allergens.lupinus.label', {
        defaultValue: 'NL',
      });
    case 'SM':
      return i18n.t('frontproductpage.allergens.macadamia.label', {
        defaultValue: 'SM',
      });
    case 'AM':
      return i18n.t('frontproductpage.allergens.milk.label', {
        defaultValue: 'AM',
      });
    case 'UM':
      return i18n.t('frontproductpage.allergens.mollusk.label', {
        defaultValue: 'UM',
      });
    case 'BM':
      return i18n.t('frontproductpage.allergens.mustard.label', {
        defaultValue: 'BM',
      });
    case 'AN':
      return i18n.t('frontproductpage.allergens.nut.label', {
        defaultValue: 'AN',
      });
    case 'GO':
      return i18n.t('frontproductpage.allergens.oat.label', {
        defaultValue: 'GO',
      });
    case 'SP':
      return i18n.t('frontproductpage.allergens.pecannut.label', {
        defaultValue: 'SP',
      });
    case 'ST':
      return i18n.t('frontproductpage.allergens.pistachio.label', {
        defaultValue: 'ST',
      });
    case 'SQ':
      return i18n.t('frontproductpage.allergens.queensland_nut.label', {
        defaultValue: 'SQ',
      });
    case 'NR':
      return i18n.t('frontproductpage.allergens.rye.label', {
        defaultValue: 'NR',
      });
    case 'AS':
      return i18n.t('frontproductpage.allergens.sesame.label', {
        defaultValue: 'AS',
      });
    case 'AC':
      return i18n.t('frontproductpage.allergens.crustacean.label', {
        defaultValue: 'AC',
      });
    case 'AY':
      return i18n.t('frontproductpage.allergens.soya.label', {
        defaultValue: 'AY',
      });
    case 'GS':
      return i18n.t('frontproductpage.allergens.spelt.label', {
        defaultValue: 'GS',
      });
    case 'AU':
      return i18n.t('frontproductpage.allergens.sulfite.label', {
        defaultValue: 'AU',
      });
    case 'SW':
      return i18n.t('frontproductpage.allergens.walnut.label', {
        defaultValue: 'SW',
      });
    case 'UW':
      return i18n.t('frontproductpage.allergens.wheat.label', {
        defaultValue: 'UW',
      });
    default:
      return null;
  }
};

const renderContains = function (allergens) {
  if (!allergens || !allergens.length) {
    return null;
  }
  return (
    <div className="Allergens__contains">
      <i className="fa fa-exclamation-triangle" />{' '}
      {i18n.t('frontproductpage.allergens.presence.label', {
        defaultValue: 'Presence:',
      })}{' '}
      {allergens.join(', ')}
    </div>
  );
};

const renderMayContain = (allergens) => {
  if (!allergens || !allergens.length) {
    return null;
  }
  return (
    <div className="Allergens__mayContain">
      <i className="fa fa-exclamation" />{' '}
      {i18n.t('frontproductpage.allergens.traces.label', {
        defaultValue: 'Traces:',
      })}{' '}
      {allergens.join(', ')}
    </div>
  );
};

const renderFreeFrom = (allergens) => {
  if (!allergens || !allergens.length) {
    return null;
  }
  return (
    <div className="Allergens__freeFrom">
      <i className="fa fa-check" />{' '}
      {i18n.t('frontproductpage.allergens.without.label', {
        defaultValue: 'Without:',
      })}{' '}
      {allergens.join(', ')}
    </div>
  );
};

const Allergens = ({ product }) => {
  if (
    !product ||
    !product.allergenTypeList ||
    !product.allergenTypeList.length
  ) {
    return null;
  }
  const contains = [];
  const mayContain = [];
  const freeFrom = [];

  product.allergenTypeList.forEach((ingredient) => {
    if (!validAllergenCodes[ingredient.allergenTypeCode.code]) {
      return;
    }
    if (ingredient.allergenPresenceCode.code === 'CONTAINS') {
      contains.push(getAllergenLabelByCode(ingredient.allergenTypeCode.code));
    }
    if (ingredient.allergenPresenceCode.code === 'MAY_CONTAIN') {
      mayContain.push(getAllergenLabelByCode(ingredient.allergenTypeCode.code));
    }
    if (ingredient.allergenPresenceCode.code === 'FREE_FROM') {
      freeFrom.push(getAllergenLabelByCode(ingredient.allergenTypeCode.code));
    }
  });

  return (
    <div className="Allergens">
      {renderContains(contains)}
      {renderMayContain(mayContain)}
      {renderFreeFrom(freeFrom)}
    </div>
  );
};

Allergens.propTypes = {
  product: productPropTypes,
};

export default Allergens;
