import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import ReactImageMagnify from 'react-image-magnify';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { productPropTypes } from '../../utils/propTypes/product';

import './index.scss';

function ProductPictures({ product }) {
  const settings = {
    showThumbs: false,
    centerMode: true,
    centerSlidePercentage: 100,
    autoPlay: false,
    showStatus: false,
    infiniteLoop: false,
    transitionTime: 0,
  };

  const pictures = (product.assets || {}).pictures;
  const sortedPictures = React.useMemo(
    () =>
      pictures
        ? [...pictures].sort((a, b) => {
            if (a.isPackshot) {
              return -1;
            }
            if (b.isPackshot) {
              return 1;
            }
            return 0;
          })
        : [],
    [pictures]
  );

  // <img src={picture.url} className="ProductPicture" height="80" alt="Product" />
  return (
    <div className="ProductPictures">
      <Carousel {...settings}>
        {sortedPictures.map((picture) => {
          const zoomSettings = {
            enlargedImagePosition: 'beside',
            enlargedImageContainerClassName: 'ProductPicture--zoomContainer',
            enlargedImageClassName: 'ProductPicture--zoom',
            imageClassName: 'ProductPicture',
            enlargedImageContainerStyle: {
              // zIndex: 10001,
            },
            smallImage: {
              height: 300,
              isFluidWidth: true,
              src: picture.exportables[1].uniformResourceIdentifier,
            },
            largeImage: {
              height: picture.height,
              width: picture.width,
              src: picture.url,
            },
          };

          return (
            <div key={picture.url}>
              <ReactImageMagnify {...zoomSettings} />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}

ProductPictures.propTypes = {
  product: productPropTypes,
};

export default ProductPictures;
