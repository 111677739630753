import 'isomorphic-fetch';

import { organizationNames, shopToOrganization } from '../../constants';

export default self.fetch.bind(self); // eslint-disable-line no-restricted-globals

export const getProductUrl = ({
  gtin,
  productVersionId,
  primaryInternalReference,
  organization,
}) => {
  const path = primaryInternalReference
    ? 'public/v1/hpp/product'
    : 'public/v1/hpp/product/private';
  const baseUrl = `${
    process.env.REACT_APP_API_URL || window.appconfig.apiUrl
  }/${path}?`;

  const params = [];
  if (productVersionId) {
    params.push(`version_id=${productVersionId}`);
  }
  if (gtin) {
    params.push(`filter_gtins_in=${gtin}`);
  }
  if (primaryInternalReference) {
    params.push(`primary_internal_reference=${primaryInternalReference}`);
  }
  if (organization && organization.id) {
    params.push(`organization_id=${organization.id}`);
  }

  return baseUrl + params.join('&');
};

export const getUrlParams = (query) => {
  const defaultLanguage = 'fr';
  if (!query) {
    return {
      language: defaultLanguage,
    };
  }

  return (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce(
    (params, param) => {
      const [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''; // eslint-disable-line no-param-reassign
      return params;
    },
    {
      language: defaultLanguage,
    }
  );
};

export const extractProduct = (responseJson) => {
  let product;
  if (Array.isArray(responseJson.data)) {
    if (!responseJson.data || !responseJson.data.length) {
      return product;
    }
    // eslint-disable-next-line prefer-destructuring
    product = responseJson.data[0]; // Ask @bignozmg
  } else {
    product = responseJson.data;
  }
  product.metadata = responseJson.metadata || {};

  return product;
};

export const buildProductParams = (urlParams) => {
  const organizationId =
    urlParams.organization_id ||
    urlParams.organization ||
    shopToOrganization[urlParams.shop_id] ||
    shopToOrganization[urlParams.productinshop_shopid] ||
    'default';
  const primaryInternalReference =
    urlParams.primary_internal_reference ||
    urlParams.productinshop_shortidinternal ||
    urlParams.productinshop_shortidout;

  const params = {
    organization: {
      id: organizationId || 'default',
      name: organizationNames[organizationId] || 'default',
    },
    gtin: urlParams.gtin,
    primaryInternalReference,
    productVersionId:
      urlParams.productversion_id || urlParams.productVersion_id,
    authenticationToken: urlParams.authentication_token,
  };

  return params;
};
