import React from 'react';

import i18n from '../../utils/i18n';
import HeaderTitle from '../headerTitle';
import { productPropTypes } from '../../utils/propTypes/product';
import { extractDeclinableByType } from '../../utils';
import './index.scss';

const Manufacturer = function ({ product }) {
  const manufacturer = extractDeclinableByType(product.contact, 'MANUFACTURER');
  const consumerSupport = extractDeclinableByType(
    product.contact,
    'CONSUMER_SUPPORT'
  );

  const phoneNumber = consumerSupport.phonenumber || manufacturer.phonenumber;
  const email = consumerSupport.email || manufacturer.email;

  if (
    !manufacturer.name &&
    !manufacturer.address &&
    !manufacturer.postcode &&
    !manufacturer.city &&
    !manufacturer.country &&
    !phoneNumber &&
    !email
  ) {
    return null;
  }

  return (
    <div className="Manufacturer">
      <HeaderTitle
        title={i18n.t('frontproductpage.manufacturer.header.title', {
          defaultValue: 'Manufacturer',
        })}
      />
      <div className="Manufacturer__address">
        <span>{manufacturer.name}</span>
        &nbsp;
        {manufacturer.address ? <div>{manufacturer.address}</div> : null}
        {manufacturer.postcode || manufacturer.city ? (
          <div>
            {manufacturer.postcode ? (
              <span>{manufacturer.postcode}&nbsp;</span>
            ) : null}
            {manufacturer.city ? <span>{manufacturer.city}</span> : null}
          </div>
        ) : null}
        {manufacturer.country ? <div>{manufacturer.country}</div> : null}
      </div>
      <div className="Manufacturer__additionalInformation">
        {phoneNumber ? (
          <a className="Manufacturer__label">
            <i className="fa fa-phone" /> {phoneNumber}
          </a>
        ) : null}
        {email ? (
          <a
            className="Manufacturer__label"
            href={`mailto:${email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-envelope-o" /> {email}
          </a>
        ) : null}
      </div>
    </div>
  );
};

Manufacturer.propTypes = {
  product: productPropTypes.isRequired,
};

export default Manufacturer;
