import foodTabs from './foodTabs';
import homecareTabs from './homecareTabs';
import healthAndBeautyTabs from './healthAndBeautyTabs';
import petfoodTabs from './petfoodTabs';

export const tabsMapping = {
  FOOD: foodTabs,
  HOMECARE: homecareTabs,
  HEALTH_AND_BEAUTY: healthAndBeautyTabs,
  PETFOOD: petfoodTabs,
};

export const defaultTabs = foodTabs;
