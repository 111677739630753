import React from 'react';

import { productPropTypes } from '../../utils/propTypes/product';
import kindToLegalMentionsMap from './constants/kindToLegalMentionsMap';

import './index.scss';

const LegalInformation = function ({ product }) {
  if (!product || !product.kind || !product.kind.code) {
    return null;
  }

  const legalMentions = kindToLegalMentionsMap[product.kind.code];
  if (!legalMentions || !legalMentions.length) {
    return null;
  }

  const randomIndex = Math.floor(Math.random() * legalMentions.length);
  const legalInformation = [legalMentions[randomIndex]];

  return (
    <div className="LegalInformation">
      <hr className="LegalInformation__separator" />
      {legalInformation.map((information, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <p key={index}>{information}</p>
      ))}
    </div>
  );
};

LegalInformation.propTypes = {
  product: productPropTypes,
};

export default LegalInformation;
