import React from 'react';

import { productPropTypes } from '../../utils/propTypes/product';
import { configurationPropTypes } from '../../utils/propTypes/configuration';
import Tab from '../../components/tab';
import TabContent from '../tabContent';
import { isTabAvailable } from '../../utils';
import { EVENT_PICK_CATEGORY } from '../../constants/tracking';
import './index.scss';

class Tabs extends React.Component {
  static computeMaxTabNumber() {
    const marginSize = 20;
    const averageTabWidth = 100;
    const navigationArrowNumber = 2;
    const spaceAvailable = window.innerWidth - marginSize * 2;
    const maxTabsNumber = Math.max(
      Math.floor(spaceAvailable / averageTabWidth) - navigationArrowNumber,
      1
    );

    return maxTabsNumber;
  }

  constructor(props) {
    super(props);
    const { configuration } = props;

    this.renderTab = this.renderTab.bind(this);
    this.renderArrowPrevious = this.renderArrowPrevious.bind(this);
    this.renderArrowNext = this.renderArrowNext.bind(this);
    this.onResize = this.onResize.bind(this);
    this.availableTabs = configuration.tabs.filter((tab) =>
      isTabAvailable(props.product, tab)
    );
    const maxTabsNumber = Tabs.computeMaxTabNumber();

    this.state = {
      activeTab: this.availableTabs[0],
      firstTabIndex: 0,
      maxTabsNumber,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    const maxTabsNumber = Tabs.computeMaxTabNumber();

    this.setState((previousState) => {
      if (previousState.maxTabsNumber === maxTabsNumber) {
        return {};
      }
      return {
        maxTabsNumber,
      };
    });
  }

  renderTab(tab) {
    let className = 'Tabs__tabWrapper';
    const isActive = tab.id === this.state.activeTab.id;
    if (isActive) {
      className += ' active';
    }
    const onClick = () => {
      window.ga('send', {
        hitType: 'event',
        eventAction: EVENT_PICK_CATEGORY,
        eventCategory: tab.name,
        eventLabel: tab.name,
      });
      this.setState({
        activeTab: tab,
      });
    };

    return (
      <div key={tab.id} className={className} onClick={onClick}>
        <Tab tab={tab} active={isActive} />
      </div>
    );
  }

  renderArrowPrevious() {
    let className = 'Tabs__navigationArrow';
    if (this.state.firstTabIndex === 0) {
      className += ' Tabs__navigationArrow--disable';
    } else {
      className += ' Tabs__navigationArrow--active';
    }
    const onClick = () =>
      this.setState((previousState) => {
        if (previousState.firstTabIndex === 0) {
          return {};
        }
        return {
          firstTabIndex: previousState.firstTabIndex - 1,
        };
      });

    return (
      <div className={className} onClick={onClick}>
        <i className="fa fa-angle-left" />
      </div>
    );
  }

  renderArrowNext() {
    let className = 'Tabs__navigationArrow';
    if (
      this.state.firstTabIndex + this.state.maxTabsNumber ===
      this.availableTabs.length
    ) {
      className += ' Tabs__navigationArrow--disable';
    } else {
      className += ' Tabs__navigationArrow--active';
    }
    const onClick = () =>
      this.setState((state) => {
        if (
          state.firstTabIndex + state.maxTabsNumber ===
          this.availableTabs.length
        ) {
          return {};
        }
        return {
          firstTabIndex: state.firstTabIndex + 1,
        };
      });

    return (
      <div className={className} onClick={onClick}>
        <i className="fa fa-angle-right" />
      </div>
    );
  }

  render() {
    const { product } = this.props;
    const { firstTabIndex } = this.state;
    const tabsToDisplay = this.availableTabs.slice(
      firstTabIndex,
      firstTabIndex + this.state.maxTabsNumber
    ); // get tabs that should be visible
    if (!tabsToDisplay || !tabsToDisplay.length) {
      return null;
    }
    const displayNavigationArrow =
      this.availableTabs.length > this.state.maxTabsNumber;

    return (
      <div className="Tabs">
        <div className="Tabs__wrapper">
          {displayNavigationArrow ? this.renderArrowPrevious() : null}
          {tabsToDisplay.map((tab) => this.renderTab(tab))}
          {displayNavigationArrow ? this.renderArrowNext() : null}
        </div>
        <TabContent product={product} tab={this.state.activeTab} />
      </div>
    );
  }
}

Tabs.propTypes = {
  product: productPropTypes.isRequired,
  configuration: configurationPropTypes.isRequired,
};

export default Tabs;
