import React from 'react';
import Img from 'react-image';

import { productPropTypes } from '../../utils/propTypes/product';

import './index.scss';

const Nutriscore = function ({ product }) {
  if (!product || !product.nutriScore) {
    return null;
  }
  const nutriScoreUrl = `https://smedia.productpage.io/api/1/referential/nutriscore/${product.nutriScore.code}.png`;

  return (
    <div className="Nutriscore">
      <Img
        src={[nutriScoreUrl]}
        className="Nutriscore__icon"
        alt={product.brand.label}
      />
    </div>
  );
};

Nutriscore.propTypes = {
  product: productPropTypes,
};

export default Nutriscore;
