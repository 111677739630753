import i18n from '../../utils/i18n';

export default [
  {
    id: 'video',
    name: i18n.t('frontproductpage.food.video_tab.label', {
      defaultValue: 'Video',
    }),
    type: 'specific',
    content: [
      {
        fields: [
          {
            model: 'assets',
            inputKind: {
              kind: 'dict',
            },
            children: [
              {
                model: 'videos',
                inputKind: {
                  kind: 'list',
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'recipes',
    name: i18n.t('frontproductpage.food.recipes_tab.label', {
      defaultValue: 'Recipes',
    }),
    type: 'specific',
    content: [
      {
        fields: [
          {
            model: 'assets',
            inputKind: {
              kind: 'dict',
            },
            children: [
              {
                model: 'enriched_contents',
                inputKind: {
                  kind: 'list',
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'benefits',
    name: i18n.t('frontproductpage.food.benefits_tab.label', {
      defaultValue: 'Details',
    }),
    type: 'generic',
    content: [
      {
        title: i18n.t('frontproductpage.food.benefits_usage.title', {
          defaultValue: 'Why use this product?',
        }),
        fields: [
          {
            model: 'nutritionalAllegations', // 'allegations',
            label: 'Avantages consommateur',
            inputKind: {
              kind: 'list',
            },
            children: [
              {
                model: 'text',
                inputKind: {
                  kind: 'text',
                },
                declinableBy: {
                  kind: 'languages',
                  model: 'expressedIn',
                  url: '/core/v3/referentials/languages',
                },
              },
            ],
          },
          {
            model: 'consumerBenefits',
            label: 'Avantages consommateur',
            inputKind: {
              kind: 'list',
            },
            children: [
              {
                model: 'text',
                inputKind: {
                  kind: 'text',
                },
                declinableBy: {
                  kind: 'languages',
                  model: 'expressedIn',
                  url: '/core/v3/referentials/languages',
                },
              },
            ],
          },
          {
            model: 'productBenefits',
            label: 'Avantages produit',
            inputKind: {
              kind: 'list',
            },
            children: [
              {
                model: 'text',
                inputKind: {
                  kind: 'text',
                },
                declinableBy: {
                  kind: 'languages',
                  model: 'expressedIn',
                  url: '/core/v3/referentials/languages',
                },
              },
            ],
          },
        ],
      },
      {
        title: i18n.t('frontproductpage.food.benefits_qualities.title', {
          defaultValue: 'Qualities not to forget!',
        }),
        fields: [
          {
            model: 'advertisedInformations',
            label: 'Allégations marketing',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'composition',
    name: i18n.t('frontproductpage.food.composition_tab.label', {
      defaultValue: 'Composition',
    }),
    type: 'generic',
    content: [
      {
        title: i18n.t('frontproductpage.food.composition_content.title', {
          defaultValue: "What's in it?",
        }),
        fields: [
          {
            model: 'composition',
            inputKind: {
              kind: 'text',
            },
            options: {
              richContent: true,
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
        ],
      },
      {
        title: i18n.t('frontproductpage.food.composition_allergens.title', {
          defaultValue: 'Allergens',
        }),
        fields: [
          {
            model: 'allergens',
            inputKind: {
              kind: 'text',
            },
            options: {
              richContent: true,
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
        ],
      },
      {
        title: i18n.t('frontproductpage.food.composition_additives.title', {
          defaultValue: 'Additives',
        }),
        fields: [
          {
            model: 'additives',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
        ],
      },
      {
        fields: [
          {
            model: 'ratioAlcohol',
            inputKind: {
              kind: 'float',
            },
            options: {
              prefix: i18n.t(
                'frontproductpage.food.composition_alcohol_ratio.label',
                { defaultValue: 'Alcohol ratio: ' }
              ),
              suffix: '°',
            },
          },
        ],
      },
      {
        title: i18n.t('frontproductpage.food.composition_flavor.title', {
          defaultValue: 'Taste and aroma',
        }),
        fields: [
          {
            model: 'flavorStatement',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'nutrition',
    name: i18n.t('frontproductpage.food.nutrition_tab.label', {
      defaultValue: 'Nutrition',
    }),
    type: 'specific',
    content: [
      {
        fields: [
          {
            model: 'isPartitionedBy',
            inputKind: {
              kind: 'list',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'advices',
    name: i18n.t('frontproductpage.food.advices_tab.label', {
      defaultValue: 'Advices',
    }),
    type: 'generic',
    content: [
      {
        title: i18n.t('frontproductpage.food.advices_usage_tips.title', {
          defaultValue: 'Usage tips',
        }),
        fields: [
          {
            model: 'advices',
            label: 'Mode d’emploi',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
          {
            model: 'pairings',
            label: 'Accords suggérés',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
        ],
      },
      {
        title: i18n.t('frontproductpage.food.advices_legal_info.title', {
          defaultValue: 'Warnings and legal information',
        }),
        fields: [
          {
            model: 'compulsoryStatements',
            inputKind: {
              kind: 'list',
            },
            children: [
              {
                model: 'text',
                inputKind: {
                  kind: 'text',
                },
                declinableBy: {
                  kind: 'languages',
                  model: 'expressedIn',
                  url: '/core/v3/referentials/languages',
                },
              },
            ],
          },
        ],
      },
      {
        title: i18n.t('frontproductpage.food.advices_dangers.title', {
          defaultValue: 'Dangers and Warnings',
        }),
        fields: [
          {
            model: 'hazardPictograms',
            inputKind: {
              kind: 'list',
            },
            children: [
              {
                model: 'isConceptualizedBy',
                inputKind: {
                  kind: 'dict',
                },
                options: {
                  pictureUrl: true,
                },
                children: [
                  {
                    model: 'pictureUrl',
                    inputKind: {
                      kind: 'text',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'tasting',
    name: i18n.t('frontproductpage.food.tasting_tab.label', {
      defaultValue: 'Tasting',
    }),
    type: 'generic',
    content: [
      {
        fields: [
          {
            model: 'appearanceOlfactory',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
          {
            model: 'appearanceTaste',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
          {
            model: 'appearanceVisual',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'conservation',
    name: i18n.t('frontproductpage.food.conservation_tab.label', {
      defaultValue: 'Conservation',
    }),
    type: 'generic',
    content: [
      {
        title: i18n.t('frontproductpage.food.conservation_advices.title', {
          defaultValue: 'Conservation advices',
        }),
        fields: [
          {
            model: 'conservations',
            inputKind: {
              kind: 'list',
            },
            children: [
              {
                model: 'text',
                inputKind: {
                  kind: 'text',
                },
                declinableBy: {
                  kind: 'languages',
                  model: 'expressedIn',
                  url: '/core/v3/referentials/languages',
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'expertise',
    name: i18n.t('frontproductpage.food.expertise_tab.label', {
      defaultValue: 'Expertise',
    }),
    type: 'generic',
    content: [
      {
        title: i18n.t('frontproductpage.food.expertise_history.title', {
          defaultValue: 'History',
        }),
        fields: [
          {
            model: 'history',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
        ],
      },
      {
        title: i18n.t('frontproductpage.food.expertise_brand.title', {
          defaultValue: 'About the brand',
        }),
        fields: [
          {
            model: 'brandHighlight',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
        ],
      },
      {
        title: i18n.t('frontproductpage.food.expertise_manufacture.title', {
          defaultValue: 'Manufacture insights',
        }),
        fields: [
          {
            model: 'manufactureInsights',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
        ],
      },
    ],
  },
];
