export const KIND_DICT = 'dict';
export const KIND_FLOAT = 'float';
export const KIND_LIST = 'list';
export const KIND_TEXT = 'text';

export const organizationNames = {
  4: 'auchandrive',
  15: 'casinodrive',
};

export const shopToOrganization = {
  1: '4',
  18: '15',
  19: '15',
  78: '780',
};
