import fr from './json/fr/translation.json';
import en from './json/en/translation.json';
import de from './json/de/translation.json';
import es from './json/es/translation.json';
import pt from './json/pt/translation.json';

export default {
  fr: { translation: fr },
  en: { translation: en },
  de: { translation: de },
  es: { translation: es },
  pt: { translation: pt },
};
