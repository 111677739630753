import React from 'react';

const IconForYou = (props) => (
  <svg width={100} height={100} viewBox="0 0 32 32" {...props}>
    <g transform="translate(53 -53)">
      <path d="M-26.7 77.1h-22.8c-1 0-1.9-.8-1.9-1.9V60.7c0-1 .8-1.9 1.9-1.9h22.8c1 0 1.9.8 1.9 1.9v14.5c0 1-.9 1.9-1.9 1.9zm-22.7-2h22.5V60.8h-22.5v14.3z" />
      <path d="M-36.1 75.6l-2 3.5-2-3.5zM-29.1 64H-47c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h17.9c.3 0 .5.2.5.5s-.3.5-.5.5zM-29.1 66H-47c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h17.9c.3 0 .5.2.5.5s-.3.5-.5.5zM-31.8 73.8h-12.5c-1.9 0-3.4-1.5-3.4-3.4s1.5-3.4 3.4-3.4h12.5c1.9 0 3.4 1.5 3.4 3.4s-1.5 3.4-3.4 3.4zm-12.6-5.9c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5h12.5c1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5h-12.5z" />
      <circle cx="-38.1" cy="70.4" r="1.9" />
      <path className="st0" d="M-38.6 69.8l1.1.6-1.1.7z" />
      <ellipse cx={-38} cy="80.9" rx="3.1" ry=".9" />
    </g>
  </svg>
);

export default IconForYou;
