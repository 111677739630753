import i18n from '../../utils/i18n';

export default [
  {
    id: 'video',
    name: i18n.t('frontproductpage.health_beauty.video_tab.label', {
      defaultValue: 'Video',
    }),
    type: 'specific',
    content: [
      {
        fields: [
          {
            model: 'assets',
            inputKind: {
              kind: 'dict',
            },
            children: [
              {
                model: 'videos',
                inputKind: {
                  kind: 'list',
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'ritual',
    name: i18n.t('frontproductpage.health_beauty.ritual_tab.label', {
      defaultValue: 'Ritual',
    }),
    type: 'specific',
    content: [
      {
        fields: [
          {
            model: 'assets',
            inputKind: {
              kind: 'dict',
            },
            children: [
              {
                model: 'enriched_contents',
                inputKind: {
                  kind: 'list',
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'benefits',
    name: i18n.t('frontproductpage.health_beauty.benefits_tab.label', {
      defaultValue: 'Benefits',
    }),
    type: 'generic',
    content: [
      {
        title: i18n.t('frontproductpage.health_beauty.benefits_usage.title', {
          defaultValue: 'Why use this product?',
        }),
        fields: [
          {
            model: 'allegations',
            label: 'Allegations',
            inputKind: {
              kind: 'list',
            },
            children: [
              {
                model: 'text',
                inputKind: {
                  kind: 'text',
                },
                declinableBy: {
                  kind: 'languages',
                  model: 'expressedIn',
                  url: '/core/v3/referentials/languages',
                },
              },
            ],
          },
          {
            model: 'consumerBenefits',
            label: 'Avantages consommateur',
            inputKind: {
              kind: 'list',
            },
            children: [
              {
                model: 'text',
                inputKind: {
                  kind: 'text',
                },
                declinableBy: {
                  kind: 'languages',
                  model: 'expressedIn',
                  url: '/core/v3/referentials/languages',
                },
              },
            ],
          },
          {
            model: 'productBenefits',
            label: 'Avantages produit',
            inputKind: {
              kind: 'list',
            },
            children: [
              {
                model: 'text',
                inputKind: {
                  kind: 'text',
                },
                declinableBy: {
                  kind: 'languages',
                  model: 'expressedIn',
                  url: '/core/v3/referentials/languages',
                },
              },
            ],
          },
        ],
      },
      {
        title: i18n.t(
          'frontproductpage.health_beauty.benefits_qualities.title',
          { defaultValue: 'Qualities not to forget!' }
        ),
        fields: [
          {
            model: 'advertisedInformations',
            label: 'Allégations marketing',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'composition',
    name: i18n.t('frontproductpage.health_beauty.composition_tab.label', {
      defaultValue: 'Composition',
    }),
    type: 'generic',
    content: [
      {
        title: i18n.t(
          'frontproductpage.health_beauty.composition_content.title',
          { defaultValue: "What's in it?" }
        ),
        fields: [
          {
            model: 'composition',
            inputKind: {
              kind: 'text',
            },
            options: {
              richContent: true,
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
        ],
      },
      {
        title: i18n.t(
          'frontproductpage.health_beauty.composition_additives.title',
          { defaultValue: 'Additives' }
        ),
        fields: [
          {
            model: 'additives',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
        ],
      },
      {
        title: i18n.t(
          'frontproductpage.health_beauty.composition_allergens.title',
          { defaultValue: 'Allergens' }
        ),
        fields: [
          {
            model: 'allergens',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
        ],
      },
      {
        title: i18n.t(
          'frontproductpage.health_beauty.composition_flavor.title',
          { defaultValue: 'Taste and aroma' }
        ),
        fields: [
          {
            model: 'flavorStatement',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'advices',
    name: i18n.t('frontproductpage.health_beauty.advices_tab.label', {
      defaultValue: 'Advices',
    }),
    type: 'generic',
    content: [
      {
        title: i18n.t(
          'frontproductpage.health_beauty.advices_usage_tips.title',
          { defaultValue: 'Usage tips' }
        ),
        fields: [
          {
            model: 'advices',
            label: 'Mode d’emploi',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
          {
            model: 'pairings',
            label: 'Accords suggérés',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
        ],
      },
      {
        title: i18n.t(
          'frontproductpage.health_beauty.advices_legal_info.title',
          { defaultValue: 'Warnings and legal information' }
        ),
        fields: [
          {
            model: 'compulsoryStatements',
            inputKind: {
              kind: 'list',
            },
            children: [
              {
                model: 'text',
                inputKind: {
                  kind: 'text',
                },
                declinableBy: {
                  kind: 'languages',
                  model: 'expressedIn',
                  url: '/core/v3/referentials/languages',
                },
              },
            ],
          },
          {
            model: 'warnings',
            inputKind: {
              kind: 'list',
            },
            children: [
              {
                model: 'text',
                inputKind: {
                  kind: 'text',
                },
                declinableBy: {
                  kind: 'languages',
                  model: 'expressedIn',
                  url: '/core/v3/referentials/languages',
                },
              },
            ],
          },
        ],
      },
      {
        title: i18n.t('frontproductpage.health_beauty.advices_dangers.title', {
          defaultValue: 'Dangers and Warnings',
        }),
        fields: [
          {
            model: 'hazardPictograms',
            inputKind: {
              kind: 'list',
            },
            children: [
              {
                model: 'isConceptualizedBy',
                inputKind: {
                  kind: 'dict',
                },
                options: {
                  pictureUrl: true,
                },
                children: [
                  {
                    model: 'pictureUrl',
                    inputKind: {
                      kind: 'text',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'conservation',
    name: i18n.t('frontproductpage.health_beauty.conservation_tab.label', {
      defaultValue: 'Conservation',
    }),
    type: 'generic',
    content: [
      {
        title: i18n.t(
          'frontproductpage.health_beauty.conservation_advices.title',
          { defaultValue: 'Conservation advices' }
        ),
        fields: [
          {
            model: 'conservations',
            inputKind: {
              kind: 'list',
            },
            children: [
              {
                model: 'text',
                inputKind: {
                  kind: 'text',
                },
                declinableBy: {
                  kind: 'languages',
                  model: 'expressedIn',
                  url: '/core/v3/referentials/languages',
                },
              },
            ],
          },
        ],
      },
      {
        title: i18n.t(
          'frontproductpage.health_beauty.conservation_period.title',
          { defaultValue: 'Period after opening (in months).' }
        ),
        fields: [
          {
            model: 'periodAfterOpening',
            inputKind: {
              kind: 'float',
            },
            options: {
              icon: 'IconPao',
              prefix: i18n.t(
                'frontproductpage.health_beauty.conservation_period_prefix.label',
                { defaultValue: 'Period after opening ' }
              ),
              suffix: i18n.t(
                'frontproductpage.health_beauty.conservation_period_suffix.label',
                { defaultValue: ' months' }
              ),
            },
          },
        ],
      },
      {
        fields: [
          {
            model: 'peremption',
            inputKind: {
              kind: 'text',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'expertise',
    name: i18n.t('frontproductpage.health_beauty.expertise_tab.label', {
      defaultValue: 'Expertise',
    }),
    type: 'generic',
    content: [
      {
        title: i18n.t(
          'frontproductpage.health_beauty.expertise_history.title',
          { defaultValue: 'History' }
        ),
        fields: [
          {
            model: 'history',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
        ],
      },
      {
        title: i18n.t('frontproductpage.health_beauty.expertise_brand.title', {
          defaultValue: 'About the brand',
        }),
        fields: [
          {
            model: 'brandHighlight',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
        ],
      },
      {
        title: i18n.t(
          'frontproductpage.health_beauty.expertise_manufacture.title',
          { defaultValue: 'Manufacture Insights' }
        ),
        fields: [
          {
            model: 'manufactureInsights',
            inputKind: {
              kind: 'text',
            },
            declinableBy: {
              kind: 'languages',
              model: 'expressedIn',
              url: '/core/v3/referentials/languages',
            },
          },
        ],
      },
    ],
  },
];
