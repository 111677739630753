const primaryColor = '#de0b1e';

const defaultTheme = {
  '--primary-color': primaryColor,
  '--secondary-color': '#3f9048',
  '--background-color': '#f7f7f7',
  '--background-color-header': 'white',
  '--border-color': '#B8B8B8',

  '--font-family': 'TitilliumText22LRegular',
  '--font-size': '14px',

  '--viewport-height': '800px',

  '--header-border-bottom-color': primaryColor,
  '--header-border-bottom-style': 'solid',
  '--header-border-bottom-width': 0,
  '--header-border-top-width': '3px',
  '--header-color': primaryColor,
  '--header-font-size': '18px',
  '--header-font-weight': 700,
  '--header-padding-bottom': 0,
};

export default defaultTheme;
