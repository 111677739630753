import '@alkem/babel-config/polyfills.min';

import React from 'react';
import ReactDOM from 'react-dom';

import './utils/ga';
import './utils/datadog';

import fetch, {
  extractProduct,
  getProductUrl,
  getUrlParams,
  buildProductParams,
} from './core/fetch';
import { tabsMapping, defaultTabs } from './core/mock/tabs';
import './index.scss';
import App from './App';
import i18n from './utils/i18n';

import defaultTheme from './constants/themes/default';
import casinoTheme from './constants/themes/casino';
import defaultOptions from './constants/options/default';
import auchanDriveOptions from './constants/options/auchanDrive';
import casinoOptions from './constants/options/casino';

async function fetchProductData() {
  try {
    const params = buildProductParams(
      getUrlParams(window.location.search || window.location.hash.slice(2))
    );

    const response = await fetch(getProductUrl(params), {
      method: 'get',
      headers: {
        Accept: 'application/json',
        authorization: `Bearer ${params.authenticationToken}`,
      },
    });

    const responseJson = await response.json();
    if (response.status !== 200 || !responseJson) {
      return {};
    }
    const product = extractProduct(responseJson);
    return { product };
  } catch (error) {
    return { error };
  }
}

function fetchTheme(configuration) {
  const themesMapping = {
    15: casinoTheme,
  };
  const organizationTheme = themesMapping[configuration.organization.id] || {};

  return Object.assign(defaultTheme, organizationTheme);
}

function fetchConfiguration(product) {
  if (!product) {
    return null;
  }

  const params = buildProductParams(
    getUrlParams(window.location.search || window.location.hash.slice(2))
  );

  const optionsMapping = {
    4: auchanDriveOptions,
    15: casinoOptions,
  };
  const organizationOptions = optionsMapping[params.organization.id] || {};

  const options = Object.assign(defaultOptions, organizationOptions);

  const configuration = {
    organization: params.organization,
    options,
    tabs: tabsMapping[product.metadata.layout] || defaultTabs,
  };

  window.ga('send', 'pageview', {
    dimension1: configuration.organization.id, // organization_id
    dimension2: params.primaryInternalReference, // external_reference
  });

  configuration.theme = fetchTheme(configuration);

  return configuration;
}

async function fetchData() {
  const { product } = await fetchProductData();
  ReactDOM.render(
    <>
      {!product && (
        <div className="App">
          <span className="ProductPageError">
            {i18n.t('frontproductpage.root.error.product', {
              defaultValue:
                'An error occured while loading the product, please try to refresh',
            })}
          </span>
        </div>
      )}
      {!!product && (
        <App configuration={fetchConfiguration(product)} product={product} />
      )}
    </>,
    document.getElementById('root')
  );
}

fetchData();
